<template>
  <div class="headers">
    <div class="home-area">
      <div class="header-left">
        <div class="welcome">Hi，欢迎来到易缆网</div>
        <template v-if="isLogin">
          <div class="company-name">{{ userinfo.companyName||userinfo.nickName }}</div>
          <div class="login-out" @click="logout">退出</div>
        </template>
        <template v-else>
          <div class="login" @click="$router.push('/login')">请登录</div>
        </template>
        <div class="attestation" v-if="userinfo.companyStatus == 5" @click="$router.push('/company/authInfo')">已认证</div>
        <div class="auth" v-else @click="handleJump()">企业认证</div>
      </div>
      <div class="header-right">
        <YlRouterLink v-for="item in routes" :key="item.name" :to="item">{{ item.title }}</YlRouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import YlRouterLink from '@/components/YlRouterLink'
import { getToken } from "@/store/mutation-types"

export default {
  components: {
    YlRouterLink
  },
  data() {
    return {
      isLogin: true
    };
  },
  mounted() {
  },
  computed: {
    userinfo() {
      return this.$store.getters["user/userInfo"];
    },
    ExternalLink() {
      return this.$store.state.app.ExternalLink
    },
    routes() {
      return [
        {
          title: '易缆原料',
          path: `${this.ExternalLink}/enterprise/home?token=${getToken()}`
        },
        {
          title: '易缆商城',
          path: `${this.ExternalLink}/cable-mall?token=${getToken()}`
        },
        {
          title: '易缆物流',
          path: `${this.ExternalLink}/cable-logistics?token=${getToken()}`
        }, {
          title: '易缆金融',
          path: `${this.ExternalLink}/cable-finance?token=${getToken()}`
        },
        {
          title: '易缆资讯',
          path: `${this.ExternalLink}/articleList?token=${getToken()}`
        },
        {
          title: '易缆企业',
          path: `${this.ExternalLink}/companyList?token=${getToken()}`
        }, {
          title: '电子账户',
          path: `${this.ExternalLink}/finance/index?token=${getToken()}`
        }, {
          title: '消息中心',
          path: '/message/messageCenter'
        }]
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
      location.reload();
    },
    handleJump() {
      location.href = `${this.ExternalLink}/certification/company?token=${getToken()}`
    }
  },
};
</script>

<style lang="scss" scoped>
.headers {
  background: #eee;
  height: 30px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;

  .home-area {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 1200px;
    height: 100%;
    justify-content: space-between;

    .header-left {
      display: flex;

      .welcome {
        margin-right: 41px;
      }

      .company-name {
        margin-right: 47px;
        font-weight: 600;
        max-width: 160px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .login-out {
        cursor: pointer;
        margin-right: 53px;
      }

      .login {
        cursor: pointer;
        color: #e60012;
        margin-right: 47px;
      }

      .auth,
      .attestation {
        cursor: pointer;
      }
    }

    .header-right {
      display: flex;

      a {
        margin-left: 30px;
        text-decoration: none;

        &:hover {
          color: #E60012;
        }
      }
    }
  }
}
</style>
