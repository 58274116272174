// 对axios进行二次封装
import axios from 'axios';
import {Loading, Message} from 'element-ui';
import router from '@/router';
import store from '@/store'
import {getToken, removeToken} from "@/store/mutation-types"
import {handlerExport} from '@/utils/tools'
// // 引入进度条
import nprogress from 'nprogress'
// // 引入进度条样式
import 'nprogress/nprogress.css'

nprogress.configure({showSpinner: false})

let loading = null; //定义loading变量


/**
 * 显示loading加载
 * @param config
 */
let showLoading = (config) => {
	//get请求，显示进度条
	if (config.method.toLowerCase() === 'get') {
		nprogress.start();
		return;
	}

	loading = Loading.service({
		fullscreen: true,
		text: 'Loading',
		customClass: 'create-isLoading',
		spinner: 'el-icon-loading',
		background: 'rgba(0, 0, 0, 0.6)'
	})
}
/**
 * 结束loading
 */
let endLoading = () => {
	nprogress.done();
	loading && loading.close();
}
let baseURL = process.env.NODE_ENV == 'development' ? '/' :process.env.VUE_APP_BASE_API
// 1:利用axios对象的方法create，去创建一个axios实例
const http = axios.create({
	baseURL: baseURL,
	timeout: 0,
})
// 请求拦截器
http.interceptors.request.use((config) => {
	// 进度条开始
	showLoading(config)
	// config: 配置对象
	const token = store.getters['user/token'];
	token && (config.headers.token = token);
	if (!config.headers.Identity) {
		config.headers.Identity = router.currentRoute.meta.Identity
	}
	return config
})
// 响应拦截器
http.interceptors.response.use((res) => {
	//成功的回调函数
	// 进度条结束
	if (res.data instanceof Blob) {
		let reader = new FileReader();
		reader.readAsText(res.data)
		reader.onload = function (result) {
			try {
				let data = JSON.parse(result.target.result)
				tip(data.message);
			} catch (error) {
				handlerExport(res)
			}
		}
		// nprogress.done()
		endLoading(res.config);
		return false
	}
	if (res.data.code !== 0 && res.data.code !== 200) {

		if (!res.config.hideTip) {
			tip(res.data.message);
		}
      if (res.data.code == 4000) {
        location.href = process.env.VUE_APP_EXTERNAL_LINK + '/certification/company?token=' + getToken();
      }
	}
	// nprogress.done()
	endLoading(res.config)
	return res.data
}, (error) => {
	//响应失败的回调函数
	const {response, config} = error;
	endLoading(config);
	if (config?.errorHandle) {
		let res = config.errorHandle(response);
		if (res instanceof Promise) {
			return res
		}
		return Promise.resolve(res);
	}
	if (response) {
		// 请求已发出，但是不在2xx的范围
		errorHandle(response.data.code, response.data.message);
		return Promise.reject(response);

	} else {
		return Promise.reject(error);
	}

})

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
	router.replace({
		path: '/login',
		query: {
			redirect: router.currentRoute.fullPath
		}
	});
}

const tip = msg => {
	Message(msg)
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
	// 状态码判断
	switch (status) {
		// 401: 未登录状态，跳转登录页
		case 401:
			toLogin();
			break;
		// 403 token过期
		// 清除token并跳转登录页
		case 403:
			tip(other || '登录过期，请重新登录');
			store.dispatch("user/logout")
			window.location.reload();
			break;
		// 404请求不存在
		case 404:
			tip(other || '请求的资源不存在');
			break;
		case 500:
			tip(other || '服务端错误！');
			router.replace({
				path: '/500',
				query: {
					redirect: router.currentRoute.fullPath
				}
			})
			break;
		default:
		// console.log(other);
	}
}

export default http
