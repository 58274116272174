import { login, getInfo } from '@/api/user';
import { setToken, removeToken, getToken } from "../mutation-types"
import { EventBus } from '@/utils/vueBus';
const state = {
  token: '',
  username: '',
  userInfo: {}
}
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, { username }) => {
    state.username = username
  },
  SET_INFO: (state, userInfo) => {
    state.userInfo = userInfo
    state.username = userInfo.nickName
    state.avatar = userInfo.avatar
  }
}
const actions = {
  login({ commit }, userInfo) {
    const { account, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ account: account.trim(), password: password }).then(response => {
        if (response.code == '200' || response.code == '0') {
          if (!response.extras.actived) {
            resolve(response)
          } else {
            const { data } = response;
            setToken(data);
            commit('SET_TOKEN', data)
            resolve(response)
            EventBus.$emit('mySocketEvent')
          }
        } else {
          reject(response)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const data = response.data
        if (response.code !== 0) {
          reject('Verification failed, please Login again.')
        }
        const { name } = data;
        commit('SET_INFO', data);
        commit('SET_NAME', { username: name });
        resolve(data)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('SET_INFO', '');
      commit('SET_NAME', '');
      commit('SET_TOKEN', '')
      // localStorage.removeItem('username');
      // localStorage.removeItem('password');
      removeToken();
      resolve();
      EventBus.$emit('stopMySocketEvent')
    })
  },
  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },
}
const getters = {
  userInfo(state) {
    return state.userInfo
  },
  // 是否个体户
  companyType: (state) => state.userInfo?.company?.type == 7,
  companyId: (state) => state.userInfo?.company?.id,
  token: () => state.token||getToken()

}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
