import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
const { sell } = store.state.app.identity;
// 使用插件
Vue.use(VueRouter)

/* Layout */
import Layout from '@/layout'

let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.push
// 重写push\replace方法
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject)
  } else {
    originPush.call(this, location, () => { }, () => { })
  }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject)
  } else {
    originReplace.call(this, location, () => { }, () => { })
  }
}

import companyRouter from './modules/company';
import contractRouter from './modules/contract';
import orderRouter from './modules/order';
import payRouter from './modules/pay';
import waybillRouter from './modules/waybill';
import financialManagementRouter from './modules/financialManagement'
import scm from './modules/SCM'
import tradeRouter from './modules/trade';
import logistics from './modules/logistics'
import baseRouter from './modules/base'
import delivery from './modules/delivery'
import shipper from './modules/shipper'
export const publicRoutes = [
  {
    path: '/redirect',
    component: Layout,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    title: '登录',
    component: () => import('@/views/Login'),
    meta: { title: '登录' }
  },
  {
    path: '/403',
    component: () => import('@/views/error-page/403'),
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
  },
  {
    path: '/500',
    component: () => import('@/views/error-page/500'),
  }
];

export const asyncRoutes = [
  {
    path: '/',
    redirect: '/home',
    fullPath: '/home',
    component: Layout,
    name: 'dashboard',
    meta: { title: '工作台', level: true, isPublic: true },
    children: [
      {
        path: 'home',
        component: () => import('@/views/Home'),
        name: 'Home',
        meta: { title: '工作台', isPublic: true },
        parentName: 'dashboard'
      }]
  },
  {
    path: '/screen',
    component: Layout,
    name: 'screen',
    meta: { title: '数据看板', level: true },
    children: [
      {
        path: 'index',
        component: () => import('@/views/Screen'),
        name: 'Screen',
        meta: { title: '数据看板' },
        parentName: 'screen'
      }
    ]
  },
  {
    path: '/message',
    component: Layout,
    name: 'homePage',
    fullPath: '/message/messageCenter',
    redirect: '/message/messageCenter',
    meta: { title: '消息中心', level: true },
    children: [
      {
        path: 'messageCenter',
        name: 'MessageCenter',
        fullPath: '/message/messageCenter',
        component: () => import('@/views/Message'),
        meta: { title: '消息中心' },
        parentName: 'homePage'
      },
      {
        path: 'detail/:id',
        name: 'MessageDetail',
        isHide: true,
        component: () => import('@/views/Message/detail.vue'),
        meta: { title: '消息中心详情', activeMenu: '/message' },
        parentName: 'homePage'
      }
    ]
  },
  {
    path: '/accountInfo',
    name: 'acctInfo',
    meta: { title: '账号信息', isPublic: true },
    component: Layout,
    isHide: true,
    children: [{
      path: '/accountInfo/index',
      name: 'AccountInfo',
      meta: { title: '账号信息', isPublic: true },
      parentName: 'acctInfo',
      isHide: true,
      component: () => import('@/views/BasicInfo/AccountInfo'),
    }]
  },
  {
    path: '/goodsManagement',
    component: Layout,
    name: 'goods',
    meta: { title: '商品管理' },
    menuType: 2,
    children: [
      {
        path: 'materialManage',
        name: 'materialManage',
        parentName: 'goods',
        fullPath: '/goodsManagement/materialManage',
        component: () => import('@/views/GoodsManagement/MaterialList'),
        meta: { title: '原材料' }
      },
      {
        path: 'cableManage',
        name: 'cableManage',
        parentName: 'goods',
        fullPath: '/goodsManagement/cableManage',
        component: () => import('@/views/GoodsManagement/CableList'),
        meta: { title: '电缆' }
      },
      {
        path: 'otherManage',
        name: 'otherManage',
        parentName: 'goods',
        fullPath: '/goodsManagement/otherManage',
        component: () => import('@/views/GoodsManagement/OthersList'),
        meta: { title: '其他' }
      },
      {
        path: 'goodsAdd/:id?',
        name: 'goodsAdd',
        parentName: 'goods',
        isHide: true,
        fullPath: '/goodsManagement/goodsAdd',
        component: () => import('@/views/GoodsManagement/GoodsAdd/index.vue'),
        meta: { title: '商品添加', isPublic: true }
      },
      {
        path: 'goodsAudit/:id?',
        name: 'goodsAudit',
        parentName: 'goods',
        isHide: true,
        fullPath: '/goodsManagement/goodsAudit/:id?',
        component: () => import('@/views/GoodsManagement/GoodsAdd/goodsAudit.vue'),
        meta: { title: '真实性审核', isPublic: true }
      },
    ]
  },
  // {
  //   path: '/storeManage',
  //   name: 'StoreManage',
  //   component: Layout,
  //   meta: { title: '店铺管理' },
  //   menuType: 2,
  //   children: [{
  //     path: 'storeList',
  //     name: 'StoreList',
  //     parentName: 'StoreManage',
  //     fullPath: '/storeManage/storeList',
  //     component: () => import('@/views/StoreManagement/storeList.vue'),
  //     meta: { title: '店铺信息' }
  //   },{
  //     path: 'mainGoods',
  //     name: 'MainGoods',
  //     parentName: 'StoreManage',
  //     fullPath: '/storeManage/mainGoods',
  //     component: () => import('@/views/StoreManagement/mainGoods.vue'),
  //     meta: { title: '主推商品',Identity: sell }
  //   },{
  //     path: 'addMainGoods',
  //     name: 'addMainGoods',
  //     parentName: 'StoreManage',
  //     isHide: true,
  //     fullPath: '/storeManage/mainGoods/addMainGoods',
  //     component: () => import('@/views/StoreManagement/addMainGoods.vue'),
  //     meta: { title: '主推商品', activeMenu: '/storeManage/mainGoods', isPublic: true,Identity: sell }
  //   }]
  // },
  orderRouter,
  contractRouter,
  financialManagementRouter,
  tradeRouter,
  logistics,
  delivery,
  shipper,
  // {
  //   path: '/finance',
  //   component: Layout,
  //   name: 'finance',
  //   meta: { title: '财务管理', Identity: sell },
  //   children: [
  //     {
  //       path: 'pay-records',
  //       component: Empty,
  //       name: "Pay-records",
  //       parentName: 'finance',
  //       meta: { title: '付款记录' },
  //       children: [
  //         {
  //           path: 'onlinePay',
  //           name: 'OnlinePay',
  //           parentName: 'Pay-records',
  //           fullPath: '/finance/pay-records/onlinePay',
  //           component: () => import('@/views/Finance/Pay-records/onlinePay'),
  //           meta: { title: '采购订单', Identity: purchase }
  //         },
  //         {
  //           path: 'offlinePay',
  //           name: 'OfflinePay',
  //           parentName: 'Pay-records',
  //           fullPath: '/finance/pay-records/offlinePay',
  //           component: () => import('@/views/Finance/Pay-records/offlinePay'),
  //           meta: { title: '销售订单', Identity: sell }
  //         },
  //       ]
  //     },
  //     {
  //       path: 'collect-records',
  //       component: Empty,
  //       name: 'collect-records',
  //       parentName: 'finance',
  //       meta: { title: '付款记录' },
  //       children: [{
  //         path: 'onlineCollect',
  //         name: 'OnlineCollect',
  //         parentName: 'collect-records',
  //         fullPath: '/finance/collect-records/onlineCollect',
  //         component: () => import('@/views/Finance/Collect-records/onlineCollect'),
  //         meta: { title: '采购订单', Identity: purchase }
  //       },
  //       {
  //         path: 'offlineCollect',
  //         name: 'OfflineCollect',
  //         parentName: 'collect-records',
  //         fullPath: '/finance/collect-records/offlineCollect',
  //         component: () => import('@/views/Finance/Collect-records/offlineCollect'),
  //         meta: { title: '销售订单', Identity: sell }
  //       }]
  //     },
  //   ]
  // },
  waybillRouter,
  payRouter,
  scm,
  companyRouter,
  baseRouter,
]
export default new VueRouter({
  mode: 'history',
  routes: publicRoutes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})